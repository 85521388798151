<template>
	<div>
		<h1 class="main-title">Сессии сеоапи</h1>
		<v-tabs v-model="activeTab" grow>
			<v-tab v-for="tab in tabs" :key="tab.id"><span>{{ tab.title }}</span></v-tab>
		</v-tabs>
		<p class="sessions-total">Всего сессий: {{ total }}</p>
		<v-data-table
			class="sessions-table"
			:headers="headers"
			:items="sortedSessions"
			:loading="isLoading"
		>
			<template v-slot:item.progress="{ item }">
				<span>{{ item.progress }}</span>
				<v-progress-linear :value="item.progress"></v-progress-linear>
			</template>

			<template v-slot:item.started_at="{ item }">
				<span>{{ formatDate(item.started_at) }}</span>
			</template>

			<template v-slot:item.finished_at="{ item }">
				<span>{{ formatDate(item.finished_at) }}</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SEARCH_ENGINE } from '@/const'

export default {
	data () {
		return {
			isLoading: false,
			activeTab: 0,
			tabs: [
				{ id: 0, title: SEARCH_ENGINE.YANDEX },
				{ id: 1, title: SEARCH_ENGINE.GOOGLE }
			],
			headers: [
				{
					text: 'session_id',
					value: 'session_id',
					align: 'center',
					width: '24%',
					divider: true
				},
				{
					text: 'priority',
					value: 'priority',
					align: 'center',
					width: '6%',
					divider: true
				},
				{
					text: 'status',
					value: 'status',
					align: 'center',
					width: '7%',
					divider: true
				},
				{
					text: 'progress',
					value: 'progress',
					align: 'center',
					width: '15%',
					divider: true
				},
				{
					text: 'queries',
					value: 'queries',
					align: 'center',
					width: '6%',
					divider: true
				},
				{
					text: 'total',
					value: 'total',
					align: 'center',
					width: '6%',
					divider: true
				},
				{
					text: 'done',
					value: 'done',
					align: 'center',
					width: '6%',
					divider: true
				},
				{
					text: 'started_at',
					value: 'started_at',
					align: 'center',
					width: '15%',
					divider: true
				},
				{
					text: 'finished_at',
					value: 'finished_at',
					align: 'center',
					width: '15%',
					divider: true
				}

			]
		}
	},

	computed: {
		...mapGetters({
			total: 'sessions/total',
			sessions: 'sessions/sessions'
		}),

		sortedSessions () {
			if (!this.sessions) {
				return []
			}
			return Object.keys(this.sessions).map((key) => this.sessions[key]).sort((a, b) => b.started_at - a.started_at)
		}
	},

	watch: {
		activeTab () {
			this.fetchData()
		}
	},

	mounted () {
		this.fetchData()
	},

	methods: {
		...mapActions({
			getSessions: 'sessions/getSessions'
		}),

		async fetchData () {
			try {
				this.isLoading = true
				await this.getSessions(this.tabs[this.activeTab].title)
			} catch (error) {
				this.$notify({ type: 'error', title: error })
			} finally {
				this.isLoading = false
			}
		},

		formatDate (timestamp) {
			if (!timestamp) {
				return '-'
			}
			const date = new Date(timestamp * 1000).toLocaleString()
			const formattedDate = date.split(', ').reverse()
			formattedDate[1] = formattedDate[1].split('.').join('-')

			return formattedDate.join(', ')
		}
	}

}
</script>

<style>
.main-title {
	margin: 20px 0px;
}

.sessions-total {
	margin-top: 30px;
	text-align: center;
}

.sessions-table {
	margin-top: 30px;
	border-top: 1px solid rgba(0,0,0,.12);
}
</style>
